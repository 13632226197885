html {
  font-family: $global-font-family;
}

/* scrollbar, only support webkit */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;

  &:hover {
    background-color: $scrollbar-hover-color;
  }
}

::selection {
  background: $selection-color;

  .dark-theme & {
    background: $selection-color-dark;
  }
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  background-color: $global-background-color;
  color: $global-font-color;

  &::before {
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.05;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }

  &.dark-theme {
    color: $global-font-color-dark;
    background-color: $global-background-color-dark;
  }
}

a {
  color: $global-link-color;
  text-decoration: none;
  transition: color 0.2s ease, border-color 0.2s ease, background 0.2s ease, opacity 0.2s ease;

  &:hover {
    color: $global-link-hover-color;
  }

  .dark-theme & {
    color: $global-link-color-dark;

    &:hover {
      color: $global-link-hover-color-dark;
    }
  }
}

$mainBkg: #BDD5EA;
$secondBkg: #6D6D65;
$mainContrastColor: lightgrey;
$darkTextColor: #323D47;
$lineColor: $mainContrastColor;
$border1: #81B1DB;
$border2: rgba(255, 255, 255, 0.25);
$arrowheadColor: $mainContrastColor;

/* Flowchart variables */

$nodeBkg: $mainBkg;
$nodeBorder: purple;
$clusterBkg: $secondBkg;
$clusterBorder: $border2;
$defaultLinkColor: $lineColor;
$titleColor: #F9FFFE;
$edgeLabelBackground: #e8e8e8;

/* Sequence Diagram variables */

$actorBorder: $border1;
$actorBkg: $mainBkg;
$actorTextColor: black;
$actorLineColor: $mainContrastColor;
$signalColor: $mainContrastColor;
$signalTextColor: $mainContrastColor;
$labelBoxBkgColor: $actorBkg;
$labelBoxBorderColor: $actorBorder;
$labelTextColor: $darkTextColor;
$loopTextColor: $mainContrastColor;
$noteBorderColor: $border2;
$noteBkgColor: #fff5ad;
$activationBorderColor: #666;
$activationBkgColor: #f4f4f4;
$sequenceNumberColor: white;

/* Gantt chart variables */

$sectionBkgColor: rgba(255, 255, 255, 0.3);
$altSectionBkgColor: white;
$sectionBkgColor2: #EAE8B9;
$taskBorderColor: rgba(255, 255, 255, 0.5);
$taskBkgColor: $mainBkg;
$taskTextColor: $darkTextColor;
$taskTextLightColor: $mainContrastColor;
$taskTextOutsideColor: $taskTextLightColor;
$taskTextClickableColor: #003163;
$activeTaskBorderColor: rgba(255, 255, 255, 0.5);
$activeTaskBkgColor: #81B1DB;
$gridColor: $mainContrastColor;
$doneTaskBkgColor: $mainContrastColor;
$doneTaskBorderColor: grey;
$critBorderColor: #E83737;
$critBkgColor: #E83737;
$taskTextDarkColor: $darkTextColor;
$todayLineColor: #DB5757;

/* state colors */
$labelColor: black;

@import '../mermaid';

.tag-cloud-tags {
  margin: 10px 0;

  a {
    display: inline-block;
    position: relative;
    margin: 5px 10px;
    word-wrap: break-word;
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    &:active,
    &:focus,
    &:hover {
      color: $global-link-hover-color;
      transform: scale(1.1);

      .dark-theme & {
        color: $global-link-hover-color-dark;
      }
    }

    small {
      color: $global-font-secondary-color;

      .dark-theme & {
        color: $global-link-hover-color-dark;
      }
    }
  }
}

@charset 'utf-8';

// ==============================
// Variables
// ==============================

// ========== Global ========== //
// Font family
$global-font-family: -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft Yahei, Segoe UI, Helvetica, Arial, sans-serif, Segoe UI Emoji !default;

// Color of the background
$global-background-color: #fff !default;
$global-background-color-dark: #292a2d !default;

// Color of the text
$global-font-color: #161209 !default;
$global-font-color-dark: #a9a9b3 !default;

// Color of the secondary text
$global-font-secondary-color: #a9a9b3 !default;
$global-font-secondary-color-dark: #5d5d5f !default;

// Color of the link
$global-link-color: #161209 !default;
$global-link-color-dark: #a9a9b3 !default;

// Color of the hover link
$global-link-hover-color: #2d96bd !default;
$global-link-hover-color-dark: #fff !default;

// Color of the border
$global-border-color: #dcdcdc !default;
$global-border-color-dark: #4a4b50 !default;
// ========== Global ========== //

// ========== Scrollbar ========== //
// Color of the scrollbar
$scrollbar-color: #87878d !default;

// Color of the hover scrollbar
$scrollbar-hover-color: #a9a9b3 !default;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: rgba(38, 139, 211, 0.2) !default;
$selection-color-dark: rgba(38, 139, 211, 0.3) !default;
// ========== Selection ========== //

// ========== Navbar ========== //
// Color of the navbar background
$navbar-background-color: #fafafa !default;
$navbar-background-color-dark: #252627 !default;

// Color of the hover navbar item
$navbar-hover-color: #161209 !default;
$navbar-hover-color-dark: #fff !default;
// ========== Navbar ========== //

// ========== Post Content ========== //
// Color of the post link
$post-link-color: #2d96bd !default;
$post-link-color-dark: #eee !default;

// Color of the hover post link
$post-link-hover-color: #ef3982 !default;
$post-link-hover-color-dark: #2d96bd !default;

// Color of the table background
$table-background-color: #fff !default;
$table-background-color-dark: #272c34 !default;

// Color of the table thead
$table-thead-color: #ededed !default;
$table-thead-color-dark: #20252b !default;
// ========== Post Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: #2d96bd !default;
$pagination-link-color-dark: #a9a9b3 !default;

// Color of the hover link in pagination
$pagination-link-hover-color: #000 !default;
$pagination-link-hover-color-dark: #fff !default;
// ========== Pagination ========== //

// ========== Code ========== //
// Color of the code
$code-color: #E74C3C !default;
$code-color-dark: #E5BF78 !default;

// Color of the code background
$code-background-color: #f5f5f5 !default;
$code-background-color-dark: #272C34 !default;

$code-info-color: #b1b0b0 !default;
$code-info-color-dark: #b1b0b0 !default;

// Font size of the code
$code-font-size: 13px !default;

// Font family of the code
$code-font-family: Source Code Pro, Menlo, Consolas, Monaco, monospace !default;

// Code type map
$code-type-map: (
  // Custom code type
  language-bash: 'Bash',
  language-c: 'C',
  language-cs: 'C#',
  language-cpp: 'C++',
  language-css: 'CSS',
  language-coffeescript: 'CoffeeScript',
  language-html: 'HTML',
  language-xml: 'XML',
  language-http: 'HTTP',
  language-json: 'JSON',
  language-java: 'Java',
  language-js: 'JavaScript',
  language-javascript: 'JavaScript',
  language-makefile: 'Makefile',
  language-markdown: 'Markdown',
  language-objectivec: 'Objective-C',
  language-php: 'PHP',
  language-perl: 'Perl',
  language-python: 'Python',
  language-ruby: 'Ruby',
  language-sql: 'SQL',
  language-shell: 'Shell',
  language-erlang: 'Erlang',
  language-go: 'Go',
  language-go-html-template: 'Go HTML Template',
  language-groovy: 'Groovy',
  language-haskell: 'Haskell',
  language-kotlin: 'Kotlin',
  language-clojure: 'Clojure',
  language-less: 'Less',
  language-lisp: 'Lisp',
  language-lua: 'Lua',
  language-matlab: 'Matlab',
  language-rust: 'Rust',
  language-scss: 'Scss',
  language-scala: 'Scala',
  language-swift: 'Swift',
  language-typescript: 'TypeScript',
  language-yml: 'YAML',
  language-yaml: 'YAML',
  language-toml: 'TOML',
  language-diff: 'Diff',
) !default;
// ========== Code ========== //

// ========== Admonition ========== //
$admonition-color-map: (
  'note': #448aff,
  'abstract': #00b0ff,
  'info': #00b8d4,
  'tip': #00bfa5,
  'success': #00c853,
  'question': #64dd17,
  'warning': #ff9100,
  'failure': #ff5252,
  'danger': #ff1744,
  'bug': #f50057,
  'example': #651fff,
  'quote': #9e9e9e,
) !default;

$admonition-background-color-map: (
  'note': rgba(68,138,255,.1),
  'abstract': rgba(0,176,255,.1),
  'info': rgba(0,184,212,.1),
  'tip': rgba(0,191,165,.1),
  'success': rgba(0,200,83,.1),
  'question': rgba(100,221,23,.1),
  'warning': rgba(255,145,0,.1),
  'failure': rgba(255,82,82,.1),
  'danger': rgba(255,23,68,.1),
  'bug': rgba(245,0,87,.1),
  'example': rgba(101,31,255,.1),
  'quote': hsla(0,0%,62%,.1),
) !default;
// ========== Admonition ========== //

.navbar {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 4rem;
  line-height: 4rem;
  background-color: $navbar-background-color;

  .dark-theme & {
    background-color: $navbar-background-color-dark;
  }

  .navbar-container {
    width: auto;
    text-align: center;
    margin: 0 4rem;
    display: flex;
    justify-content: space-between;

    .navbar-header a {
      padding: 0 8px;
      font-size: 20px;

      i {
        line-height: 2em;
      }
    }

    .navbar-menu a {
      padding: 0 8px;

      &.active {
        font-weight: 900;
        color: $navbar-hover-color;

        .dark-theme & {
          color: $navbar-hover-color-dark;
        }
      }
    }
  }
}

.navbar-mobile {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease 0s;

  .navbar-container {
    padding: 0;
    margin: 0;
    height: 4.5em;
    line-height: 4.5em;
    background: $global-background-color;

    .navbar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 18px;
      padding-right: 1em;
      padding-left: 1em;
      box-sizing: border-box;

      .navbar-header-title {
        font-size: 20px;
      }

      .menu-toggle {
        cursor: pointer;
        line-height: 4.5em;

        span {
          display: block;
          background: #000;
          width: 24px;
          height: 2px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          -webkit-transition: .2s margin .2s, .2s transform;
          -moz-transition: .2s margin .2s, .2s transform;
          transition: .2s margin .2s, .2s transform;

          .dark-theme & {
            background: $global-font-color-dark;
          }
        }

        span:nth-child(1) {
          margin-bottom: 8px;
        }

        span:nth-child(3) {
          margin-top: 8px;
        }

        &.active {
          span {
            -webkit-transition: .2s margin, .2s transform .2s;
            -moz-transition: .2s margin, .2s transform .2s;
            transition: .2s margin, .2s transform .2s;
          }

          span:nth-child(1) {
            -moz-transform: rotate(45deg) translate(4px, 6px);
            -ms-transform: rotate(45deg) translate(4px, 6px);
            -webkit-transform: rotate(45deg) translate(4px, 6px);
            transform: rotate(45deg) translate(4px, 6px);
          }

          span:nth-child(2) {
            opacity: 0
          }

          span:nth-child(3) {
            -moz-transform: rotate(-45deg) translate(8px, -10px);
            -ms-transform: rotate(-45deg) translate(8px, -10px);
            -webkit-transform: rotate(-45deg) translate(8px, -10px);
            transform: rotate(-45deg) translate(8px, -10px);
          }
        }
      }
    }

    .navbar-menu {
      text-align: center;
      background: $global-background-color;
      border-top: 2px solid $global-font-color;
      display: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);

      a {
        display: block;
        line-height: 2.5em;
      }

      &.active {
        display: block;
      }

      .dark-theme & {
        background: $global-background-color-dark;
        border-top: 2px solid $global-font-color-dark;
      }
    }

    .dark-theme & {
      background: $global-background-color-dark;
    }
  }
}

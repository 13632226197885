/** Section styling */

.mermaid-main-font {
  font-family: "trebuchet ms", verdana, arial;
  font-family: var(--mermaid-font-family);
}

.section {
  stroke: none;
  opacity: 0.2;
}

.section0 {
  fill: $sectionBkgColor;
}

.section2 {
  fill: $sectionBkgColor2;
}

.section1,
.section3 {
  fill: $altSectionBkgColor;
  opacity: 0.2;
}

.sectionTitle0 {
  fill: $titleColor;
}

.sectionTitle1 {
  fill: $titleColor;
}

.sectionTitle2 {
  fill: $titleColor;
}

.sectionTitle3 {
  fill: $titleColor;
}

.sectionTitle {
  text-anchor: start;
  font-size: 11px;
  text-height: 14px;
  font-family: 'trebuchet ms', verdana, arial;
  font-family: var(--mermaid-font-family);

}


/* Grid and axis */

.grid .tick {
  stroke: $gridColor;
  opacity: 0.3;
  shape-rendering: crispEdges;
  text {
    font-family: 'trebuchet ms', verdana, arial;
    font-family: var(--mermaid-font-family);
  }
}

.grid path {
  stroke-width: 0;
}


/* Today line */

.today {
  fill: none;
  stroke: $todayLineColor;
  stroke-width: 2px;
}


/* Task styling */

/* Default task */

.task {
  stroke-width: 2;
}

.taskText {
  text-anchor: middle;
  font-family: 'trebuchet ms', verdana, arial;
  font-family: var(--mermaid-font-family);
}

.taskText:not([font-size]) {
  font-size: 11px;
}

.taskTextOutsideRight {
  fill: $taskTextDarkColor;
  text-anchor: start;
  font-size: 11px;
  font-family: 'trebuchet ms', verdana, arial;
  font-family: var(--mermaid-font-family);

}

.taskTextOutsideLeft {
  fill: $taskTextDarkColor;
  text-anchor: end;
  font-size: 11px;
}

/* Special case clickable */
.task.clickable {
  cursor: pointer;
}
.taskText.clickable {
  cursor: pointer;
  fill: $taskTextClickableColor !important;
  font-weight: bold;
}

.taskTextOutsideLeft.clickable {
  cursor: pointer;
  fill: $taskTextClickableColor !important;
  font-weight: bold;
}

.taskTextOutsideRight.clickable {
  cursor: pointer;
  fill: $taskTextClickableColor !important;
  font-weight: bold;
}

/* Specific task settings for the sections*/

.taskText0,
.taskText1,
.taskText2,
.taskText3 {
  fill: $taskTextColor;
}

.task0,
.task1,
.task2,
.task3 {
  fill: $taskBkgColor;
  stroke: $taskBorderColor;
}

.taskTextOutside0,
.taskTextOutside2
{
  fill: $taskTextOutsideColor;
}

.taskTextOutside1,
.taskTextOutside3 {
  fill: $taskTextOutsideColor;
}


/* Active task */

.active0,
.active1,
.active2,
.active3 {
  fill: $activeTaskBkgColor;
  stroke: $activeTaskBorderColor;
}

.activeText0,
.activeText1,
.activeText2,
.activeText3 {
  fill: $taskTextDarkColor !important;
}


/* Completed task */

.done0,
.done1,
.done2,
.done3 {
  stroke: $doneTaskBorderColor;
  fill: $doneTaskBkgColor;
  stroke-width: 2;
}

.doneText0,
.doneText1,
.doneText2,
.doneText3 {
  fill: $taskTextDarkColor !important;
}


/* Tasks on the critical line */

.crit0,
.crit1,
.crit2,
.crit3 {
  stroke: $critBorderColor;
  fill: $critBkgColor;
  stroke-width: 2;
}

.activeCrit0,
.activeCrit1,
.activeCrit2,
.activeCrit3 {
  stroke: $critBorderColor;
  fill: $activeTaskBkgColor;
  stroke-width: 2;
}

.doneCrit0,
.doneCrit1,
.doneCrit2,
.doneCrit3 {
  stroke: $critBorderColor;
  fill: $doneTaskBkgColor;
  stroke-width: 2;
  cursor: pointer;
  shape-rendering: crispEdges;
}

.milestone {
  transform: rotate(45deg) scale(0.8,0.8);
}

.milestoneText {
  font-style: italic;
}
.doneCritText0,
.doneCritText1,
.doneCritText2,
.doneCritText3 {
  fill: $taskTextDarkColor !important;
}

.activeCritText0,
.activeCritText1,
.activeCritText2,
.activeCritText3 {
  fill: $taskTextDarkColor !important;
}

.titleText {
  text-anchor: middle;
  font-size: 18px;
  fill: $taskTextDarkColor;
  font-family: 'trebuchet ms', verdana, arial;
  font-family: var(--mermaid-font-family);
}

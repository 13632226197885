.admonition {
  position: relative;
  margin: .9765em 0;
  padding: 0 .75rem;
  background-color: map-get($admonition-background-color-map, 'note');
  border-left: .25rem solid map-get($admonition-color-map, 'note');
  overflow: auto;

  .admonition-title {
    margin: 0 -0.75rem;
    padding: .5rem .75rem .5rem 2.5rem;
    border-bottom: .1rem solid map-get($admonition-background-color-map, 'note');
    background-color: map-get($admonition-background-color-map, 'note');
    font-weight: 700;
  }

  i.icon {
    font-size: 16px;
    color: map-get($admonition-color-map, 'note');
    cursor: auto;
    position: absolute;
    left: .75rem;
    top: .75rem;
  }

  @each $type, $color in $admonition-color-map {
    &.#{$type} {
      border-left-color: $color;

      i.icon {
        color: $color;
      }
    }
  }

  @each $type, $color in $admonition-background-color-map {
    &.#{$type} {
      background-color: $color;

      .admonition-title {
        border-bottom-color: $color;
        background-color: $color;
      }
    }
  }

  &:last-child {
    margin-bottom: .75rem;
  }
}

details.admonition {
  summary {
    display: block;
    outline: none;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    i.details {
      position: absolute;
      top: .75rem;
      right: .75rem;
      color: $global-font-color;

      .dark-theme & {
        color: $global-font-color-dark;
      }
    }
  }
}

details.admonition[open] {
  i.details {
    transform: rotate(180deg);
  }
}

.actor {
  stroke: $actorBorder;
  fill: $actorBkg;
}

text.actor {
  fill: $actorTextColor;
  stroke: none;
}

.actor-line {
  stroke: $actorLineColor;
}

.messageLine0 {
  stroke-width: 1.5;
  stroke-dasharray: '2 2';
  stroke: $signalColor;
}

.messageLine1 {
  stroke-width: 1.5;
  stroke-dasharray: '2 2';
  stroke: $signalColor;
}

#arrowhead {
  fill: $signalColor;
}

.sequenceNumber {
  fill: $sequenceNumberColor;
}

#sequencenumber {
  fill: $signalColor;
}

#crosshead path {
  fill: $signalColor !important;
  stroke: $signalColor !important;
}

.messageText {
  fill: $signalTextColor;
  stroke: none;
}

.labelBox {
  stroke: $labelBoxBorderColor;
  fill: $labelBoxBkgColor;
}

.labelText {
  fill: $labelTextColor;
  stroke: none;
}

.loopText {
  fill: $loopTextColor;
  stroke: none;
}

.loopLine {
  stroke-width: 2;
  stroke-dasharray: '2 2';
  stroke: $labelBoxBorderColor;
}

.note {
  //stroke: #decc93;
  stroke: $noteBorderColor;
  fill: $noteBkgColor;
}

.noteText {
  fill: black;
  stroke: none;
  font-family: 'trebuchet ms', verdana, arial;
  font-family: var(--mermaid-font-family);
  font-size: 14px;
}

.activation0 {
  fill: $activationBkgColor;
  stroke: $activationBorderColor;
}

.activation1 {
  fill: $activationBkgColor;
  stroke: $activationBorderColor;
}

.activation2 {
  fill: $activationBkgColor;
  stroke: $activationBorderColor;
}

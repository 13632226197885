@media only screen and (max-width: 1800px) {
  .page {
    max-width: 780px;

    .post-toc {
      width: 300px;
      margin-left: 800px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .page {
    max-width: 680px;

    .post-toc {
      width: 240px;
      margin-left: 700px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    max-width: 560px;

    .post-toc {
      width: 180px;
      margin-left: 580px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .navbar {
    .navbar-container {
      margin: 0 1rem;
    }
  }

  .page {
    max-width: 80%;

    .post-toc {
      display: none;
    }

    .post-toc-mobile {
      display: block;
    }
  }
}

@media only screen and (max-width: 560px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: block;
  }

  .page {
    max-width: 100%;

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }

  .copyright {
    .copyright-line {
      .icp-splitter {
        display: none;
      }
      .icp-br {
        display: block;
      }
    }
  }

  .dynamic-to-top {
    display: none !important;
  }
}
